import Cookies from 'js-cookie';
import { sendCallUrl } from '../functions/settings';



async function postData(url, data) {
  try {
    const response = await fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json', // Assuming you are sending JSON data
      },
      body: JSON.stringify(data),
    });

    if (!response.ok) {
      throw new Error('Network response was not ok');
    }

    const responseBody = await response.json();
    return responseBody;
  } catch (error) {
    console.error('Error:', error);
    return {};
  }
}
function replaceWithBr(inputString) {
  if (typeof inputString !== 'string') {
    throw new Error('Input must be a string');
  }

  return inputString.replace(/\n/g, '<br>');
}


async function fetchUserLinks({setUserLinks}) {
  try {
    const response = await postData(`https://api.usuallytech.com/contractor/linksNoAuth.php`);
    setUserLinks(response);

  } catch (error) {
    console.error(error);
  }
};


const formatText = (text) => {
  return text
    .split("_")
    .join(" ")
    .replace(
      /\w\S*/g,
      (word) => word.charAt(0).toUpperCase() + word.substr(1).toLowerCase()
    );
};


function getUserToken(){
  //this function gets the user token from their cookies
    // Get the value of the 'authToken' cookie
    const authToken = Cookies.get('authToken');

    // Return the value of 'authToken'
    return authToken;

}
function humanReadableDate(dateTimeString) {
  const months = [
      "January", "February", "March", "April", "May", "June", 
      "July", "August", "September", "October", "November", "December"
  ];

  const daysOfWeek = [
      "Sunday", "Monday", "Tuesday", "Wednesday", 
      "Thursday", "Friday", "Saturday"
  ];

  let dt = new Date(dateTimeString);
  
  const year = dt.getFullYear();
  const month = months[dt.getMonth()];
  const day = dt.getDate();
  const dayOfWeek = daysOfWeek[dt.getDay()];
  let hours = dt.getHours();
  const minutes = dt.getMinutes().toString().padStart(2, '0');
  const seconds = dt.getSeconds().toString().padStart(2, '0');

  const ampm = hours >= 12 ? 'PM' : 'AM';
  hours = hours % 12;
  hours = hours ? hours : 12; // Adjust hour 0 (which is 12 AM)
  
  return `${dayOfWeek}, ${month} ${day}, ${year} ${hours}:${minutes} ${ampm}`;
}
function friendlyPhoneNumber(phoneNumber) {
  // Remove any non-numeric characters from the input

  if(phoneNumber){
      let numbersOnly = phoneNumber.replace(/\D/g, '');

  // Extract the area code and the local number parts
  let areaCode = numbersOnly.substring(1, 4);
  let firstPart = numbersOnly.substring(4, 7);
  let secondPart = numbersOnly.substring(7, 11);

  // Combine the parts to get the formatted number
  return `(${areaCode}) ${firstPart}-${secondPart}`;
  }else{
      return null;
  }

}

const sendCall = async (caller_ID, number_to_call, user_extension) => {
  const data = {
      caller_ID: caller_ID,
      number_to_call: number_to_call,
      user_extension: user_extension
  }

  const response = await postData(`${sendCallUrl}`, data);
  return response;
}

function friendlyCurrency(amount) {
  if (typeof amount !== 'string' && typeof amount !== 'number') {
    return 'Invalid input';
  }

  // Convert the input to a number if it's a string.
  const numericAmount = typeof amount === 'string' ? parseFloat(amount) : amount;

  // Check if the numericAmount is a valid number.
  if (isNaN(numericAmount)) {
    return 'Invalid input';
  }

  // Use toFixed to round the number to 2 decimal places and get a string with 2 decimal places.
  const formattedAmount = numericAmount.toFixed(2);

  // Use regular expressions to add commas as thousands separators.
  const parts = formattedAmount.split('.');
  parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',');

  // Add the dollar sign and combine the parts.
  return '$' + parts.join('.');
}

function friendlyTime(timeString) {
  // Split the time string into hours, minutes, and seconds
  const [hours, minutes, seconds] = timeString.split(':');

  // Convert hours to a number
  const hoursNum = parseInt(hours, 10);

  // Determine if it's AM or PM
  const period = hoursNum >= 12 ? 'PM' : 'AM';

  // Convert hours to 12-hour format
  const friendlyHours = hoursNum % 12 || 12;

  // Create the formatted time string
  const formattedTime = `${friendlyHours}:${minutes} ${period}`;

  return formattedTime;
}



const logout = () => {
  localStorage.removeItem("userCallerID");
  localStorage.removeItem("userExtension");
  localStorage.removeItem("userId");
  window.location.href = "/";
};








export { postData, replaceWithBr, fetchUserLinks, getUserToken, formatText, humanReadableDate, friendlyPhoneNumber, sendCall, logout, friendlyCurrency, friendlyTime };