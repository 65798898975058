import { createContext, useState } from 'react';

// Create a new context for User Info
export const UserInfoContext = createContext({
  userId: '',
  setUserId: () => {},
  userCallerId: '',
  setUserCallerId: () => {},
  userExtension: '',
  setUserExtension: () => {},
  
});

export default function UserInfoContextProvider({ children }) {
  const [userId, setUserId] = useState(null);
  const [userCallerId, setUserCallerId] = useState(null);
  const [userExtension, setUserExtension] = useState(null);

  return (
    <UserInfoContext.Provider value={{userId, setUserId, userCallerId, setUserCallerId, userExtension, setUserExtension}}>
      {children}
    </UserInfoContext.Provider>
  );
}
