import React from "react";
import { Container, Paper } from "@mui/material";
import Link from "@mui/material/Link";
import Box from "@mui/material/Box";

export default function WhiteBox(props) {

  if (props.centered) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center">
        <Container maxWidth="xl" sx={{ mt: 0, mb: 0 }}>
          <Paper sx={{ my: { xs: 3, md: 6 }, p: { xs: 2, md: 3 } }}>
            {props.children}
          </Paper>
        </Container>
      </Box>
    );
  } else {
  }
  return (

    <Container maxWidth="xl" sx={{ mt: 0, mb: 0 }}>

      <Paper sx={{ my: { xs: 3, md: 3 }, p: { xs: 2, md: 3 } }}>
        {props.children}
      </Paper>
    </Container>
  );
}
