import React, { useState, useEffect } from "react";
import { Grid, Typography, ButtonGroup, IconButton } from "@mui/material";
import WhiteBox from "./components/Whitebox";
import Title from "./components/Title";
import CallButton from "./components/CallButton";
import DeletePhoneNumberSample from "./components/DeletePhoneNumberSample";
import AddPhoneNumber from "./components/AddPhoneNumber";
import AddNumberToDNC from "./components/AddNumberToDNC";
import EditIcon from "@mui/icons-material/Edit";
import { formatText } from "./functions/functions";


export default function App({ id }) {

  const [phoneNumbers, setPhoneNumbers] = useState([]);
  const [phoneListKey, setPhoneListKey] = useState(null);
  const [phoneContactName, setPhoneContactName] = React.useState(null);
  const [editingItem, setEditingItem] = React.useState(null);


  useEffect(() => {
    
 const sampleData = [
  {
    phone_number_ID: 1,
    phone_number: '555-111-2345',
    status: 'new',
  },
  {
    phone_number_ID: 2,
    phone_number: '555-222-3456',
    status: 'valid',
    owner_name: "Mark Johnson"
  },
  {
    phone_number_ID: 3,
    phone_number: '555-333-4567',
    status: 'invalid',
  },
  {
    phone_number_ID: 4,
    phone_number: '555-444-5678',
    status: 'user_validated',
  },
  {
    phone_number_ID: 5,
    phone_number: '555-555-6789',
    status: 'do_not_call',
  },
];

    setPhoneNumbers(sampleData);
    
  }, [phoneListKey]);

  return (
    <WhiteBox>
      <Title>Phone Numbers</Title>
      <div>After answering, press 1 to connect.</div>

      <Grid container spacing={2} key={phoneListKey}>
        {phoneNumbers.map((phoneNumberObj, index) => (
          <Grid item xs={12} sm={6} key={phoneNumberObj.phone_number_ID}>
            {/* This will be the Edit Icon */}

            {phoneNumberObj.owner_name ? (
              <Typography variant="body2" color="textSecondary">
                Name: <strong>{phoneNumberObj.owner_name}</strong>
              </Typography>
            ) : null}
            {phoneNumberObj.phone_number_type ? (
              <Typography variant="body2" color="textSecondary">
                Number Type:{" "}
                <strong>{formatText(phoneNumberObj.phone_number_type)}</strong>
              </Typography>
            ) : null}

            {phoneNumberObj.status ? (
              <Typography variant="body2" color="textSecondary">
                Phone Number Status:{" "}
                <strong>{formatText(phoneNumberObj.status)}</strong>
              </Typography>
            ) : null}

            {phoneNumberObj.source ? (
              <Typography variant="body2" color="textSecondary">
                Source: <strong>{formatText(phoneNumberObj.source)}</strong>
              </Typography>
            ) : null}

            <ButtonGroup
              variant="text"
              size="small"
              style={{ marginTop: "5px" }}
            >
              {phoneNumberObj.status ? (
                <CallButton
                  setPhoneListKey={setPhoneListKey}
                  items={phoneNumberObj}
                  editingItem={editingItem}
                  setEditingItem={setEditingItem}
                />
              ) : null}

              <AddNumberToDNC />
            </ButtonGroup>
          </Grid>
        ))}
      </Grid>

      <AddPhoneNumber setPhoneListKey={setPhoneListKey} property_ID={id} />
    </WhiteBox>
  );
}
