import * as React from 'react';
import PropTypes from 'prop-types';
import Typography from '@mui/material/Typography';
import { Container } from '@mui/material';

function transformToTitleCase(inputString) {
  if (typeof inputString !== 'string') {
    return '';
  }
  

  return inputString.replace(/\w\S*/g, (txt) => txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase());
}

function Title({ children, style }) {
  let contentAsString;
  
  if (Array.isArray(children)) {
    contentAsString = children.join(' ');  // Convert array to space-separated string
  } else {
    contentAsString = String(children);
  }

  const transformedTitle = transformToTitleCase(contentAsString);

  return (
    <Typography style={style} component="h2" variant="h5" color="primary" gutterBottom>
      {transformedTitle}
    </Typography>
  );
}

Title.propTypes = {
  children: PropTypes.node,
};

function TitleSpacing(props) {
  return (
    <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
      {props.children}
    </Container>
  );
}

export { TitleSpacing };
export default Title;
