import * as React from "react";
import { Grid, Button, Stack } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Slide from "@mui/material/Slide";
import { TransitionProps } from "@mui/material/transitions";
import PhoneIcon from "@mui/icons-material/Phone";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import ReportIcon from "@mui/icons-material/Report";
import { postData, friendlyPhoneNumber } from "../functions/functions";
import { apiScriptUrl } from "../functions/settings";

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>,
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function AddNumberToDNCSample({
  addNumberToDNCOpen,
  setAddNumberToDNCOpen,
  phoneNumberId,
  setPhoneListKey,
  phoneNumber,

}) {
  const handleAddToDNC = async () => {

    setPhoneListKey(Date.now());
    setAddNumberToDNCOpen(false);
  };



  const handleClickOpen = () => {
    setAddNumberToDNCOpen(true);
  };

  const handleClose = () => {
    setAddNumberToDNCOpen(false);
  };

  return (
    <>
      {/*}
      <Stack direction="row" spacing={2}>
        <React.Fragment>
          <Button
            onClick={handleClickOpen}
            style={{ marginTop: 5 }}
            variant="outlined"
           
            startIcon={<ReportIcon />}
          >
            Do Not Call
          </Button>
        </React.Fragment>
      </Stack>
  {*/}
      <Dialog
        open={addNumberToDNCOpen}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle>Place Number On Do Not Call List?</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            <div>
              Number will be placed on a sitewide Do Not Call list and will not
              be called even if it is in another property.
            </div>
            <Stack direction="row" spacing={2}>
              <React.Fragment>
                <Button
                  onClick={handleAddToDNC}
                  style={{ marginTop: 5 }}
                  variant="contained"
                  color="error"
                  startIcon={<ReportIcon />}
                  endIcon={<ReportIcon />}
                >
                  <div>Add To DNC</div>
                </Button>
              </React.Fragment>
            </Stack>
          </DialogContentText>
        </DialogContent>
      </Dialog>
    </>
  );
}
