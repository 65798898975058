import * as React from "react";
import Chip from "@mui/material/Chip";
import PhoneIcon from "@mui/icons-material/Phone";
import MenuIcon from "@mui/icons-material/Menu";
import { useState, useEffect, useContext } from "react";
import DeletePhoneNumberSample from "./DeletePhoneNumberSample";
import AddNumberToDNCSample from "./AddNumberToDNCSample";
import { Grid, Button, Stack } from "@mui/material";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { friendlyPhoneNumber, postData } from "../functions/functions";
import TextField from "@mui/material/TextField";
import { sendCall } from "../functions/functions";
import { UserInfoContext } from "../contexts/UserInfoContextProvider";
import LinearProgress from '@mui/material/LinearProgress';
import { apiScriptUrl } from "../functions/settings";

const EditName = ({ editingItem, setEditingItem, setPhoneListKey }) => {
  const [name, setName] = React.useState(null);
  //some JSX for a MUIV5 textfield and a submit button

  const handleSubmit = async () => {

    setEditingItem(0);
    setPhoneListKey(Date.now());
  };

  return (
    <div>
      <TextField
        variant="standard"
        label="Edit Name"
        value={name}
        onChange={(e) => setName(e.target.value)}
        fullWidth
        style={{ marginBottom: "16px" }}
      />
      <Button
        variant="contained"
        color="primary"
        onClick={() => handleSubmit()}
      >
        Submit
      </Button>
    </div>
  );
};

export default function CallButton({
  items,
  setPhoneListKey,
  editingItem,
  setEditingItem,
}) {
  // Convert a single item into an array if needed
  const itemList = Array.isArray(items) ? items : [items];
  const [deletePhoneOpen, setDeletePhoneOpen] = React.useState(false);
  const [addNumberToDNCOpen, setAddNumberToDNCOpen] = React.useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [numbersVisible, setNumbersVisible] = React.useState(true);
  const { userCallerId, userExtension } = useContext(UserInfoContext);

  const menuOpen = Boolean(anchorEl);
  const handleMenuClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleDeletePhoneClick = () => {
    setAnchorEl(false);
    setDeletePhoneOpen(true);
  };

  const handleAddToDNCClick = () => {
    setAnchorEl(false);
    setAddNumberToDNCOpen(true);
  };

  const makeCall = async (caller_ID, number_to_call, user_extension) => {
    setNumbersVisible(false);

    let response;

    setNumbersVisible(true);
    return response;
  };

 

  const handleChangeStatus = (status, phone_number_ID) => () => {

    setPhoneListKey(Date.now());
    setAnchorEl(false);
  };

  // Dummy handleClick and handleDelete functions for demonstration purposes.
  // Replace these with your actual handlers if needed.
  const handleClick = () => {
    console.log("Chip clicked");
  };


  const handleEditNameClick = (phone_number_ID) => {
    setPhoneListKey(phone_number_ID);
    setEditingItem(phone_number_ID);
    setAnchorEl(false);
  };

  useEffect(() => {}, [editingItem]);

  return (
    <React.Fragment key={editingItem}>


{ numbersVisible ?     
<>
{itemList.map((item) => (
        <React.Fragment key={item.phone_number_ID}>
          {" "}
          {/* Changed from editingItem for uniqueness */}
          <div>
            <Menu
              id="basic-menu"
              anchorEl={anchorEl}
              open={menuOpen}
              onClose={handleMenuClose}
              MenuListProps={{
                "aria-labelledby": "basic-button",
              }}
            >
              <MenuItem onClick={handleDeletePhoneClick}>Delete</MenuItem>
              <MenuItem
                onClick={(e) => handleEditNameClick(item.phone_number_ID)}
              >
                Edit Name
              </MenuItem>
              <MenuItem onClick={handleAddToDNCClick}>
                Add To Do Not Call List
              </MenuItem>
              <MenuItem >
                Mark As Valid
              </MenuItem>
              <MenuItem >
                Mark As Invalid
              </MenuItem>
            </Menu>
          </div>
          <Grid container direction="column" alignItems="center">
            <Grid item>
              <Chip
                label={friendlyPhoneNumber(item.phone_number)}
                aria-controls={menuOpen ? "basic-menu" : undefined}
                aria-haspopup="true"
                aria-expanded={menuOpen ? "true" : undefined}
                onClick={() => makeCall(userCallerId, item.phone_number, userExtension)}
                onDelete={handleMenuClick}
                icon={<PhoneIcon />}
                variant="filled"
                style={{ marginTop: 5 }}
                color={
                  item.status === "new"
                    ? "default"
                    : item.status === "valid"
                    ? "primary"
                    : item.status === "invalid"
                    ? "error"
                    : item.status === "user_validated"
                    ? "success"
                    : item.status === "do_not_call"
                    ? "warning"
                    : "default"
                }
                deleteIcon={<MenuIcon fontSize="small" />}
              />
            </Grid>
            {editingItem === item.phone_number_ID ? (
              <Grid item>
                <EditName
                  editingItem={editingItem}
                  setEditingItem={setEditingItem}
                  setPhoneListKey={setPhoneListKey}
                />
              </Grid>
            ) : null}
          </Grid>
          <DeletePhoneNumberSample
            deletePhoneOpen={deletePhoneOpen}
            setDeletePhoneOpen={setDeletePhoneOpen}
            phoneNumberId={item.phone_number_ID}
            phoneNumber={item.phone_number}
            setPhoneListKey={setPhoneListKey}
          />
          <AddNumberToDNCSample
            addNumberToDNCOpen={addNumberToDNCOpen}
            setAddNumberToDNCOpen={setAddNumberToDNCOpen}
            phoneNumberId={item.phone_number_ID}
            phoneNumber={item.phone_number}
            setPhoneListKey={setPhoneListKey}
          />
        </React.Fragment>
      ))}
      </>
       :<div>Call Connecting...</div> }




    </React.Fragment>
  );
}
