import * as React from "react";
import { Grid, Button, Stack } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Slide from "@mui/material/Slide";
import { TransitionProps } from "@mui/material/transitions";
import PhoneIcon from "@mui/icons-material/Phone";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import ReportIcon from "@mui/icons-material/Report";
import { postData, friendlyPhoneNumber } from "../functions/functions";
import { apiScriptUrl } from "../functions/settings";

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>,
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function DeletePhoneNumberSample({deletePhoneOpen,setDeletePhoneOpen,phoneNumberId,phoneNumber,setPhoneListKey}) {
  const handleDelete = async () => {

    setPhoneListKey(Date.now());

    setDeletePhoneOpen(false);
  };

  const handleClose = () => {
    setDeletePhoneOpen(false);
  };

  return (
    <>
      <Dialog
        open={deletePhoneOpen}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle>Delete Number From This Entry?</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            <div>
              Are you sure you wish to delete{" "}
              <b>{friendlyPhoneNumber(phoneNumber)}</b>?
            </div>
            <div>
              Number will be permanently deleted from this property and cannot
              be recovered.
            </div>
            <Stack direction="row" spacing={2}>
              <React.Fragment>
                <Button
                  onClick={handleDelete}
                  style={{ marginTop: 5 }}
                  variant="contained"
                  color="error"
                  startIcon={<DeleteForeverIcon />}
                >
                  <div>Delete Number</div>
                </Button>
              </React.Fragment>
            </Stack>
          </DialogContentText>
        </DialogContent>
      </Dialog>
    </>
  );
}
