import * as React from "react";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { postData } from "../functions/functions";
import { apiScriptUrl } from "../functions/settings";

export default function AddPhoneNumber({property_ID, setPhoneListKey}) {
  const [open, setOpen] = React.useState(false);
  const [phoneNumber, setPhoneNumber] = React.useState("");
  const [name, setName] = React.useState("");

  const formatPhoneNumber = (input) => {
    const cleaned = ("" + input).replace(/\D/g, "");
    if (cleaned.length <= 3) {
      return cleaned;
    } else if (cleaned.length <= 6) {
      return `(${cleaned.substring(0, 3)}) ${cleaned.substring(3)}`;
    } else {
      return `(${cleaned.substring(0, 3)}) ${cleaned.substring(
        3,
        6
      )}-${cleaned.substring(6, 10)}`;
    }
  };

  const handleChange = (e) => {
    const value = e.target.value;
    const formatted = formatPhoneNumber(value);
    if (formatted.length <= 14) {
      // Limit length to (xxx) xxx-xxxx pattern
      setPhoneNumber(formatted);
    }
  };

  const handleNameChange = (e) => {
    setName(e.target.value);
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleAddNewNumber = async () => {
    const data = {
      'phone_number': phoneNumber,
      'name': name,
      'property_ID': property_ID,
    }; // Data to be sent to the API
    const responseBody = await postData(
      `${apiScriptUrl}?add_phone_number`,
      data
    );
    
    if (responseBody) {
      console.log(responseBody);
    if(
        responseBody.success === "true"
    ){
      setPhoneNumber('');
        setPhoneListKey(phoneNumber);

        handleClose();
    }
    } else {
      console.error("Error adding phone number.");
    }
  };

  return (
    <div>
      <Button style={{marginTop: 5}}variant="outlined" onClick={handleClickOpen}>
        Add Phone Number
      </Button>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Add New Number</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Add a new number below and it will be linked with this property.
          </DialogContentText>
          {/*}
          <TextField
            autoFocus
            margin="dense"
            id="name"
            label="Name"
            type="text"
            fullWidth
            variant="standard"
            value={name}
            onChange={handleNameChange}
          />
          {*/}
          <TextField
            autoFocus
            margin="dense"
            id="phoneNumber"
            label="Phone Number"
            type="tel"
            fullWidth
            variant="standard"
            value={phoneNumber}
            onChange={handleChange}
            inputProps={{ inputMode: "numeric", pattern: "[0-9]*" }}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button onClick={handleAddNewNumber}>Add New Number</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
