
const userTokenTimeout = 300; //in seconds

const APIURL = `https://portal.titancapitalfundinggroup.com`;

const numberQualifierApiUrl = `${APIURL}/requests/number_qualifier.php`;
const propertySearchApiUrl = `${APIURL}/requests/properties.php?property_search`;
const apiScriptUrl = `${APIURL}/requests/properties.php`;
const loginScriptUrl = `${APIURL}/requests/login_endpoint.php`;

//this is the URL of the 
const sendCallUrl = `${APIURL}/requests/send_call.php?caller_ID=&target_number=&user_extension=1001`;





const reactUrl = `${APIURL}`;





export { userTokenTimeout, APIURL, propertySearchApiUrl, sendCallUrl, apiScriptUrl, reactUrl, numberQualifierApiUrl, loginScriptUrl };